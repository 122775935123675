import React from 'react';

export function fetchAPI(path, method, body) {
  console.log('fetchAPI:', `${process.env.REACT_APP_BACKEND_URL_BASE}${path}`, method, body)
  const url = `${process.env.REACT_APP_BACKEND_URL_BASE}${path}`
  return fetch(url, {
    method: method || 'GET',
    headers: {
      'Content-Type': 'application/json'
    },
    body: body
  })
}

export function useInterval(callback, delay) {
  const savedCallback = React.useRef();

  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    let id = setInterval(tick, delay);
    return () => clearInterval(id);
  }, []);
};

