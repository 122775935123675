import { useState, useEffect } from 'react';
import { useInterval, fetchAPI } from '../helpers';
import { useLoaderData, useParams } from 'react-router-dom';

export function NodeView(props) {
  let { node, plugin } = props;
  const PluginView = global.__plugins__[plugin].view;
  const [data, setData] = useState(null);
  const fetchData = async () => { 
    console.log('Fetching node data:', node.id);
    try {
      let data = await fetchAPI(`/node/${node.id}`, 'GET').then(res => res.json())
      setData(data)
    } catch (err) {
      console.log('Error fetching node data:', err)
      setData(null)
    }
  }
  useEffect( _ => { fetchData() }, [])
  useInterval(fetchData, 5000);
  console.log('NodeView data:', node.id, node.plugin);
  return <div className="flex flex-col h-full">
      <PluginView node={data} />
    </div>
}

export function NodeViewSelector(props) {
  const {id, plugin} = useParams();
  console.log('NodeViewSelector:', id, plugin);
  const data = useLoaderData();
  let node =  null;
  let content = null;
  if (data) {
    console.log('NodeViewSelector data:', data);
    for (let n of data) {
      if (id === n.id || n.default) {
        console.log('Found node:', n);
        node = n;
        break
      }
    }
    content = <NodeView node={node} plugin={"chat"} /> 
  } else {
    content = <div>Loading...</div> 
  }
  return <div className="flex flex-col h-full">
    { content }
    </div>
}
