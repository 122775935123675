import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom'; 
import './index.css';
import { fetchAPI } from './helpers';
import { NodeView, NodeViewSelector } from './components/NodeView';


global.__plugins__ = {};

let routes = [
  {
    path: '/',
    loader: () => {
      return fetchAPI(`/node`, 'GET').then(res => res.json()).then(data => data.nodes)
    },
    element: <NodeViewSelector />,
  },
  {
    path: '/node/:id/:plugin',
    //element: global.__plugins__['chat'].view, //@TODO
    loader: () => {
      return fetchAPI(`/node`, 'GET').then(res => res.json()).then(data => data.nodes)
    },
    element: <NodeViewSelector />,
  },
];

const registerPlugin = async (plugin) => {
  let module = import(`./plugins/${plugin}/plugin.js`); 
  module = await module;
  global.__plugins__[plugin] = {};
  let pluginInfo = module.default();
  global.__plugins__[plugin].view = pluginInfo.view;
  routes = routes.concat(pluginInfo.routes);
};

await registerPlugin('chat');
await registerPlugin('admin');

const router = createBrowserRouter(routes);

function App() {
  return (
    <div className="h-screen flex">
    
    <div className="flex-grow">
      <RouterProvider router={router} />
    </div>
      <div className="flex flex-col min-w-[180px] h-full bg-gray-100 border-l-2 border-gray-90 shadow-1g">
        <div>
          <div className="text-2xl font-extrabold text-white bg-indigo-400 tracking-tight text-center">
    LLMPOD
          </div>


        </div>
        
        <div className="flex-grow"></div>
        
        <div className="p-5 text-gray-600 text-sm text-center"><a href="#">Built with LLMPod</a></div>
      </div>
    </div>
  );
}

export default App;
